import './styles.css';
import { useFormik } from "formik";
import { alertService, userService } from '../_services';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    Button,
    Header,
} from 'semantic-ui-react';
import ReactCodeInput from 'react-code-input';
import QRCode from 'react-qr-code';

export default function  SelectMFA(){
    const history = useHistory();
    const [mfaKey, setMfaKey] = useState('');
    const [pinCode, setPinCode] = useState("");
    const [btnIsPressed, setBtnIsPressed] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [showQR, setShowQR] = useState(true);

    const email = sessionStorage.getItem('userEmail');
    const authPayload = {
        "email": sessionStorage.getItem('userEmail'),
        "password": sessionStorage.getItem('userPassword')
    }

    if(sessionStorage.getItem('token').length != 0){
        const payload = {
            'email': sessionStorage.getItem('userEmail'),
            'mfa_type': 'google'
        }
        userService.updateMfa(payload).then((data)=>{
            setMfaKey(data['mfa_key']);
        }).catch((error)=>{
            alertService.error(error);
        });
    }

    const pinCodeStyle = {
        className: ReactCodeInput,
        inputStyle: {
            margin:  '1rem',
            MozAppearance: 'textfield',
            width: '4rem',
            fontSize: '1.25rem',
            textAlign: 'center',
            height: '3.5rem',
        }
    }

    const handlePinChange = pinCode => {
        setPinCode(pinCode);
        setBtnIsPressed(false);
    };

    const handleSubmit = () => {
        setSubmitting(true);
        const payload = {
            'email': email,
            'mfa_code': pinCode
        };
        userService.checkMfa(payload).then((data)=>{
            sessionStorage.setItem('record_per_page', 10);
            sessionStorage.setItem('registering', false);
            history.push('/');
        }).catch((error)=>{
            alertService.error(error);
            setPinCode('');
            return setSubmitting(false);
        });
    }

    return (
        <div>
        <div className="container mt-5">
            <div class="formWrapper">
                <div className='borderBox'>
                    <div className='borderPadding'>
                        <Header as='h1' textAlign='center'>Verify Multi-factor Authentication</Header>
                        <div className='promptHeader'>
                                Please download Google Authenticator and scan the QR code
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <div style={{'text-align': 'center'}}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "30%", width: "30%", marginBottom: '0.75rem'}}
                                    value={`otpauth://totp/Clinical%20Portal%20MFA?secret=${mfaKey}`}
                                    viewBox={`0 0 64 64`}
                                    display={showQR?'true':'none'}
                                />
                            </div>
                            <div style={{'padding-top': '15px'}}/>
                            <ReactCodeInput 
                                type='text'
                                fields={6}
                                className='confirmation-code'
                                {...pinCodeStyle}
                                onChange={handlePinChange}
                            />
                            <Button 
                                type="submit"
                                disabled={isSubmitting} 
                                className="btn btn-primary"
                                onSubmit={handleSubmit}
                            >
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}