import React, { useEffect, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { 
    Grid,
    Menu,
    Segment,
    Sidebar
} from 'semantic-ui-react';

import { List } from './List';
import { AddEdit } from './AddEdit';
import DropdownFilter from '../_components/Filter';
import SideBarMenu from '../_components/SideBarMenu';

function Providers({ match, location }) {
    const { path } = match;
    
    const [activeItem, setActiveItem] = useState('Provider List');
    const [pathname, setPathname] = useState(<SideBarMenu mode="list" path={path} page="Provider"/>);

    const handleItemClick = (event, {name}) => {
        setActiveItem(name);
    };

    useEffect(() => {
        const loc = location.pathname;
        if (loc === "/providers") {
            setPathname(<SideBarMenu mode={"list"} path={path} page="Provider"/>);
        } else if (loc.match(`${path}/edit/`)) {
            setPathname(<SideBarMenu mode={"edit"} path={path} page="Provider"/>);
        } else if (loc.match(`${path}/view/`)) {
            setPathname(<SideBarMenu mode={"view"} path={path} page="Provider"/>);
        } else if (loc === `${path}/add`) {
            setPathname(<SideBarMenu mode={"add"} path={path} page="Provider"/>);
        }
    }, [location.pathname]);

    return (
        <Grid columns={1} className="pr-4">
            <Grid.Column>
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                    as={Menu}
                    width="248px"
                    visible={true}
                    >
                        {pathname}
                    </Sidebar>
                    <Sidebar.Pusher className="pushed-segment pr-3">
                        <Segment basic>
                            <Switch>
                                <Route exact path={path} component={List} />
                                <Route path={`${path}/add`} component={AddEdit}/>
                                <Route path={`${path}/view/:id`} component={AddEdit} />
                                <Route path={`${path}/edit/:id`} component={AddEdit} />
                            </Switch>
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Grid.Column>
        </Grid>
    );
}

export { Providers };
