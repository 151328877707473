import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const customerService = {
    resendLoginInfo,
    unlockAccount
};

function resendLoginInfo(params) {
    return fetchWrapper.post(`${baseUrl}/password/resend_password`, params);
}

function unlockAccount(params) {
    return fetchWrapper.post(`${baseUrl}/unlock_account`, params);
}