import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { Alert, Banner } from '../_components';
import * as Yup from 'yup';
import { Modal, ModalActions, Button, ModalContent, Header} from 'semantic-ui-react';
import './Login.css';
import { sessionService, alertService, userService } from '../_services';
import ReactCodeInput from 'react-code-input';
import { version } from '../models/Constants';

export default function Login() {
  const [passwordState, setPassState] = useState(true);
  const history = useHistory();
  const [password, setPassword] = useState();
  const [showMfa, setMfaVisibility] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const url = window.location.href;
  const segments = url.split('/');

  const validationSchema = Yup.object().shape({
      email: Yup.string()
          .required('Email is required'),
      password: Yup.string()
          .required('Password is required')
  });

   // functions to build form returned by useForm() hook
   const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
       resolver: yupResolver(validationSchema)
   });

  function setSessionInfo(session){
    const role = session["user_role"];
    // Allow only valid roles: Admin, Study Manager and Customer Service
    if (role === "Es_administrator" || role === "Es_clinical_site_manager" || role === "Es_customer_service")
    {
      setToken(session["id_token"]);
      sessionStorage.setItem("first_name", session.first_name);
      sessionStorage.setItem("last_name", session.last_name);
      sessionStorage.setItem("refresh_token", session.refresh_token);
      sessionStorage.setItem("user_role", session.user_role);
      sessionStorage.setItem("record_per_page", 10);
      sessionStorage.setItem('historyView', JSON.stringify([]));
      sessionStorage.setItem('token',session.id_token);
      if(segments.includes('request')){
        let reportId = searchParams.get('id');
        history.push(`/requests/report/${reportId}`)
      }else{
        history.push('/patients');
      }
      alertService.success('Session added', { keepAfterRouteChange: true });
    }
    else
    {
      alertService.error("User role does not have permission to login!") 
    }
  }

  function createSession(data) {
      const payload = {
        ...data,
        'mfa_code':''
      };
      return sessionService.login(payload)
          .then(session => {
            setSessionInfo(session);
        })
        .catch((error) => {
            if(error == "MFA Required"){
              setMfaVisibility(true);
            }else{
              alertService.error(error);
            }
        });
    }

    function handleMfaSubmit(){
      let emailValue = document.getElementById('emailInput').value;
      let passwordValue = document.getElementById('passwordInput').value;
      const payload = {
        'email': emailValue,
        'password': passwordValue,
        'mfa_code': pinCode
      };

      sessionService.login(payload)
      .then((session) => {
        setSessionInfo(session);
      })
      .catch((error) => {
        setMfaVisibility(false);
        alertService.error(error)
      });


    }

  function setToken(userToken) {
    sessionStorage.setItem('token', userToken);
  }

  const handlePinChange = pinCode => {
    setPinCode(pinCode);
  };

  const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      handleMfaSubmit();
    }
  }


  return(
    <div>
      <div className="container pt-4 pb-4">  
        <form onSubmit={handleSubmit(createSession)} onReset={reset} id='loginForm'>
            <h1>Login</h1>
            <div className="form-row">
                <div className="form-group col-6">
                    <label>Email</label>
                    <input id='emailInput' name="email" autocomplete='off' type="text" ref={register} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                </div>
                <div className="form-group col">
                  <label>
                    Password
                    { <em> {password}</em> }
                  </label>
                  <input id='passwordInput' name="password" readOnly={passwordState} autocomplete='off' type="password" ref={register} onFocus={()=>{setPassState(false)}} style={{}} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                  <div className="invalid-feedback">{errors.password?.message}</div>
              </div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Submit
                </button>
            </div>
            <form onKeyDown={onKeyDownHandler}>
            <Modal
              closeOnDimmerClick={true}
              closeOnEscape={true}
              closeIcon
              open={showMfa}
              actions={['Snooze', { key: 'done', content: 'Done', positive: true }]}
              dimmer='blurring'
              centered={true}
              onClose={()=>setMfaVisibility(false)}
            >
              <ModalContent>
                <div style={{'textAlign': 'center'}}>
                  <Header style={{'padding-bottom': '3.5rem'}}>Please Enter MFA Code</Header>
                  <ReactCodeInput
                    id="pinCode"
                    type="text"
                    fontSize='1.25rem'
                    fields={6}
                    on
                    onChange={handlePinChange}
                    onSubmit={handleMfaSubmit}
                    closeOnEscape={true}
                  />
                  
                </div>
              </ModalContent>
              <ModalActions>
                <Button
                  color='red'
                  content='Cancel'
                  onClick={()=>setMfaVisibility(false)}
                  onSubmit={handleMfaSubmit}
                />
                <Button
                  color='blue'
                  onClick={handleMfaSubmit}
                >
                  {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  Submit
                </Button>
              </ModalActions>
            </Modal>
            </form>
            
        </form>
      </div>
      <div style={{ position:'absolute', bottom:0, marginLeft: '15px'}}>Version: {version}</div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
