import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Logout } from './Logout';

function Session({ match }) {
    const { path } = "/logout";
    return (
        <Switch>
            <Route exact path={path} component={Logout} />
        </Switch>
    );
}

export { Session };
