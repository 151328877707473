import React, { useEffect, useState } from 'react';

function DeviceMetrics({history, match}) {
    return(
        <div >
            <iframe 
            src= {process.env.REACT_APP_GRAFANA_METRICS_URL}
            width="1350"
            height="800" 
            frameborder="0" 
            style={{paddingLeft:30, align:'center'}}/>
        </div>
    )
}

export {DeviceMetrics};