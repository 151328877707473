import React, { useEffect, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { 
    Button,
    Grid,
    Menu,
    Modal,
    Segment,
    Sidebar,
    Icon
} from 'semantic-ui-react';

import { DeviceMetrics } from './Device';
import SideBarMenu from '../_components/SideBarMenu';
  


function Metrics({ match, location }) {
    const { path } = match;
    const [activeItem, setActiveItem] = useState('Device Metrics');
    const [pathname, setPathname] = useState(<SideBarMenu mode="device" path={path} page="Metrics"/>);
    const [visible, setVisible] = useState(true);

    const handleSidebarOpenClose = () => {
        setVisible(!visible);
    }

    const SidebarVisible = () => {
        const [visible, setVisible] = useState(true)
    }

    const handleItemClick = (event, {name}) => {
        setActiveItem(name);
    };

    useEffect(() => {
        const loc = location.pathname;
        if (loc === "/metrics") {
            setPathname(<SideBarMenu mode={"device"} path={path} page="Metrics"/>);
        } 
    }, [location.pathname]);
    
    return (
        <Grid columns={1} className="pr-4">
            <Grid.Column>
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                    as={Menu}
                    width="248px"
                    visible={visible}
                    style={{zIndex:-1}}
                    >
                        {pathname}
                    </Sidebar>
                    <Sidebar.Pusher className="pushed-segment pr-3">
                        <Segment basic>
                        {visible ? <Icon name='arrow left' onClick={handleSidebarOpenClose} size={'large'} style={{position:'absolute',paddingRight:10}}/> : <Icon name='arrow right' onClick={handleSidebarOpenClose} size={'large'} style={{position:'absolute',paddingRight:10}}/>}
                            <Switch>
                                <Route exact path={path} component={DeviceMetrics} />
                            </Switch>
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Grid.Column>
        </Grid>
    );
}

export { Metrics };
