import React, { useState, useEffect, useContext } from 'react';
import {
    Form,
    FormField,
    Button,
    Divider,
    Segment,
    Label,
    Header,
    Grid,
    Icon,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';
import { useFormik } from "formik";
import * as Yup from 'yup';

// internal imports
import './styles.css';
import { alertService, sessionService, userService } from '../_services';

const createTempRandomPassword = () => {
    let length              = 10;
    var result              = '';
    var characters          = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&';
    var charactersLength    = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
   return result;
}

export default function ConfirmEmail(){
    const history = useHistory();
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);
    const [pinCode, setPinCode] = useState("");
    const [btnIsPressed, setBtnIsPressed] = useState(false);
    const checkPinCode = () => {
        const isPinCodeValid = pinCode === CORRECT_PIN_CODE;
    
        setBtnIsPressed(true);
        setIsPinCodeValid(isPinCodeValid);
        if (!isPinCodeValid) setPinCode("");
      };

    const handlePinChange = pinCode => {
        setPinCode(pinCode);
        setBtnIsPressed(false);
    };

      const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required'),
    });

    const pinCodeStyle = {
        className: ReactCodeInput,
        inputStyle: {
            margin:  '1rem',
            MozAppearance: 'textfield',
            width: '4rem',
            fontSize: '1.25rem',
            textAlign: 'center',
            height: '3.5rem',
        }
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            mfa: "",
        },
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            onSubmit(formData);
        }
    });

    const onSubmit = () => {
        const url = window.location.href;
        const segments = url.split('/'); // Split the URL by '/'
        const emailToken = segments[segments.length - 1]; // Access the last segment
        const tempPassword = createTempRandomPassword();
        const payload = {
            'email': formik.values.email,
            'otp': pinCode,
            'emailToken': emailToken,
            'password': tempPassword
        };
        if(emailToken.length == 0 || emailToken == 'ConfirmEmail'){
            return alertService.error('Please use link emailed to you');
        }
        userService.confirmEmail(payload).then((data)=>{
            sessionService.create({'email': formik.values.email, 'password': tempPassword}).then((data)=>{
                sessionStorage.setItem('token', data['id_token']);
                sessionStorage.setItem('userEmail', formik.values.email);
                sessionStorage.setItem('tempPassword', tempPassword)
                sessionStorage.setItem('user_role', data['user_role']);
                sessionStorage.setItem('refresh_token', data['refresh_token']);
                sessionStorage.setItem('registering', true);
                formik.setSubmitting(false);
                console.log('pushing to /SetPassword');
                return history.push('/SetPassword');
            }).catch((error) => {
                alertService.error(error);
                return formik.setSubmitting(false);
            })
            
        }).catch((error)=>{
            alertService.error(error);
            return formik.setSubmitting(false);
        });
    }

    return (
    <div>
        <div className="container mt-5">
            <div class="formWrapper">
                <div className='borderBox'>
                    <div className='borderPadding'>
                        <Header as='h1' textAlign='center'>Enter Verification Details</Header>
                        <Header as='h3' textAlign='center'>Please enter your Email</Header>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Input
                                name='email'
                                autoComplete='off'
                                type='text'
                                placeholder='Email'
                                label='Email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            <div className='formHeader'>
                                Please enter the 6-digit verification code that was sent to your email.
                            </div>
                            <ReactCodeInput 
                            type='text'
                            fields={6}
                            className='confirmation-code'
                            {...pinCodeStyle}
                            onChange={handlePinChange}
                            />
                            <Button className='submitButton' type='submit' onClick={onSubmit}>Submit</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}