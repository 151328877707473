import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useFormik } from "formik";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    Form,
    Button,
    Checkbox,
    GridColumn,
    GridRow,
    Grid,
    Label,
    Header,
    Image,
    Modal,
    Radio,
    TextArea,
} from 'semantic-ui-react';

import { 
    Countries,
    States,
} from '../models/Constants';

import { Link } from 'react-router-dom';
import { 
    facilityService, 
    patientService, 
    alertService,
    userService
} from '../_services';
import { materialService } from '../_services/material.service';

function AddEdit({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    const [dateBenchmark, setDateBenchmark] = useState(new Date());
    const [request, setRequest] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [altRecipient, setAltRecipient] = useState("");
    const [altPhone, setAltPhone] = useState("");
    const [altEmail, setAltEmail] = useState("");
    const [open, setOpen] = useState(false);
    const [altCheck, setAltCheck] = useState(false)
    const [value, setRadioValue] = useState('null');
    const [transferOrder, setTransferOrder] = useState(false);
    const handleChange = async (event, data) => {
        // Clear dropdown for new selection
        setRadioValue(data.value);
        if(data.value == 'fieldRep') {
            setTransferOrder(true);
            formik.setFieldValue('sales_order_number', 'TO');
        }else{
            setTransferOrder(false);
            formik.setFieldValue('sales_order_number', 'SO');
        }
        // Set radio field to selection list
        await formik.setFieldValue("patient_id", "", true);
    };
    const [options, setOptions] = useState([]);

    // Used for quantity select options component
    const [quantities, setQuantities] = useState([]);

    // View mode
    const [newShippingAddress, setNewShippingAddress] = useState(true);

    useEffect(() => {
        formik.validateField('patient_id')
      }, [value]);

    // Modal inline style
    const inlineStyle = {
        modal : {
            position: 'relative',
            marginTop: '0px !important',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 'auto',
        }
      };

    // For iteration
    const requestedProducts = [
        {   key: "patch_unit",
            text: "Jewel Patch Kit",
            selected: false,
            partNumber: '900-00031',
            catalogNumber: 'JWL1-PTCH-K-01',
            transferOnly: false,
        },
        {   key: "mdu",
            text: "Jewel Defibrillator Kit",
            selected: false,
            partNumber: '900-00032',
            catalogNumber: 'JWL1-MDU0-K-01',
            transferOnly: false,
        },
        {   key: "starter_kit",
            text: "Jewel Starter Kit",
            selected: false,
            partNumber: '900-00036',
            catalogNumber: 'JWL1-STRT-K-01',
            transferOnly: false,
        },
        {   key: "placement_accessory",
            text: "Jewel Placement Accessory",
            selected: false,
            partNumber: '900-00033',
            catalogNumber: 'JWL1-PLCT-K01',
            transferOnly: false,
        },
        {   key: "removal_kit",
            text: "Jewel Patch Removal Kit",
            selected: false,
            partNumber: '820-00600',
            catalogNumber: 'JWL1-RMVL-K-01',
            transferOnly: false,
        },
        {   key: "hair_trimmer",
            text: "Hair Trimmer",
            selected: false,
            partNumber: '445-00014',
            catalogNumber: 'JWL1-HTRM-E-01',
            transferOnly: false,
        },
        {   key: "patient_guide",
            text: "Jewel Patient Guide",
            selected: false,
            partNumber: '320-00027',
            catalogNumber: '320-00049',
            transferOnly: false,
        },
        {   key: "patient_card",
            text: "Jewel Patient Card",
            selected: false,
            partNumber: '320-00018',
            catalogNumber: '320-00005',
            transferOnly: false,
        },
        {   key: "patient_quick_guide",
            text: "Jewel Patient Quick Guide",
            selected: false,
            partNumber: '320-00019',
            catalogNumber: '320-00004',
            transferOnly: false,
        },
        {   key: "ifu",
            text: "Jewel Wearable Patch Defibrillator IFU",
            selected: false,
            partNumber: '320-00050',
            catalogNumber: '320-00050',
            transferOnly: false,
        },
        {   key: "adhesive_laminate",
            text: "Laminate Sheets",
            selected: false,
            partNumber: '820-00280',
            catalogNumber: 'JWL1-LMTS-E-01',
            transferOnly: false,
        },
        {
            key: "rivet_small",
            text: "Rivets",
            selected: false,
            partNumber: "432-00023",
            catalogNumber: "JWL1-RVTS-E-01",
            transferOnly: true,
        },
        {
            key: "demo_belt",
            text: "Demo Belt",
            selected: false,
            partNumber: "820-00770",
            catalogNumber: "820-00770",
            transferOnly: true,
        },
        {
            key: "demo_battery",
            text: "Demo Battery",
            selected: false,
            partNumber: "820-00814",
            catalogNumber: "820-00814",
            transferOnly: true,
        },
        {
            key: "demo_device",
            text: "Demo Device",
            selected: false,
            partNumber: "820-00771",
            catalogNumber: "820-00771",
            transferOnly: true,
        },
        {
            key: "demo_device_w_case",
            text: "Demo Device With Case",
            selected: false,
            partNumber: "820-00774",
            catalogNumber: "820-00774",
            transferOnly: true,
        },
        {
            key: "mdu_return",
            text: "MDU Return Material",
            selected: false,
            partNumber: 'N/A',
            catalogNumber: 'N/A',
            transferOnly: false,
        },
        {
            key: "patch_unit_return",
            text: "Patch Return Material",
            selected: false,
            partNumber: 'N/A',
            catalogNumber: 'N/A',
            transferOnly: false,
        },
        {
            key: "placement_accessory_return",
            text: "PA Return Material",
            selected: false,
            partNumber: 'N/A',
            catalogNumber: 'N/A',
            transferOnly: false,
        },
        {
            key: "return_label",
            text: "Return Labels",
            selected: false,
            partNumber: 'N/A',
            catalogNumber: 'N/A',
            transferOnly: false,
        },
    ];
    // form validation rules
    const validationSchema = Yup.object().shape({
        requestor_first_name: Yup.string()
            .required("Requestor first name is required"),
        requestor_last_name: Yup.string()
            .required("Requestor last name is required"),
        date_requested: Yup.string()
            .required("Data requested is required in format: MM-DD-YYYY"),
        date_needed: Yup.string()
            .required("Date needed is required in format: MM-DD-YYYY"),
        sales_order_number: Yup.string(),
        patient_id: Yup.string()
            .when([], {
                is: () => value === 'patient',
                then: schema => Yup.string()
                .required("Patient Id is required")
                .matches(/^PT-1\d{7}$/, 'Must be in the format PT-1xxxxxxx'),
                otherwise: schema => Yup.string()
            }),
        site_name: Yup.string(),
        site_id: Yup.string(),
        recipient_name: Yup.string().required('Contact name is required'),
        complaint_request: Yup.boolean()
            .required("Complaint request is required"),
        address: Yup.object().shape({
            street_address_1: Yup.string()
                .required("shipping address 1 is required"),
            street_address_2: Yup.string(),
            city: Yup.string()
                .required("City is required"),
            state: Yup.string()
                .required("State is required"),
            postal_code: Yup.string()
                .required("Postal code is required"),
            country: Yup.string()
                .required("Country is required"),
        }),
        recipient_email: Yup.string()
            .required("Recipient email is required"),
        recipient_phone: Yup.string()
            .required("Recipient phone is required"),
        requested_product: Yup.object().shape({
            patch_unit: Yup.number(),
            mdu: Yup.number(),
            starter_kit: Yup.number(),
            placement_accessory: Yup.number(),
            removal_kit: Yup.number(),
            hair_trimmer: Yup.number(),
            adhesive_laminate: Yup.number(),
            patient_guide: Yup.number(),
            patient_card: Yup.number(),
            patient_quick_guide: Yup.number(),
            ifu: Yup.number(),
            mdu_return: Yup.number(),
            patch_unit_return: Yup.number(),
            placement_accessory_return: Yup.number(),
            return_label: Yup.number(),
        }),
        special_instructions: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            requestor_first_name: "",
            requestor_last_name: "",
            date_requested: "",
            date_needed: "",
            sales_order_number: "",
            patient_id: "",
            site_id: "",
            site_name: "",
            complaint_request: "",
            address: {
                street_address_1: "",
                street_address_2: "",
                city: "",
                state: "",
                postal_code: "",
                country: ""
            },
            recipient_name: "",
            recipient_email: "",
            recipient_phone: "",
            requested_product: {
                patch_unit: 0,
                mdu: 0,
                starter_kit: 0,
                placement_accessory: 0,
                removal_kit: 0,
                hair_trimmer: 0,
                adhesive_laminate: 0,
                rivet_small: 0,
                patient_guide: 0,
                patient_card: 0,
                patient_quick_guide: 0,
                ifu: 0,
                mdu_return: 0,
                patch_unit_return: 0,
                placement_accessory_return: 0,
                return_label: 0,
                demo_belt: 0,
                demo_battery: 0,
                demo_device: 0,
                demo_device_w_case: 0,
            },
            special_instructions: "",
        },
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            onSubmit(formData);
        }
    });

    // functions to build form returned by useForm() hook
    const { setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // Returns true if the date_needed is set to before our date benchmark (4 days after date_requested)
    const validateDateNeeded = () => {
        const dateStr = formik.values.date_needed;
        const [month, day, year] = dateStr.split("-");
        const dateNeeded = new Date(year, month-1, day);
        if(dateNeeded <= dateBenchmark) return true;
        return false;
    }

    const onSubmit = (data) => {
        if (validateDateNeeded()){
            setOpen(false);
            formik.setSubmitting(false);
            return alertService.error('Date-Needed must be after Date-Requested')
        }
        return materialService.create(data)
            .then((resp) => {
                history.push('.');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    const loadFieldValues = async (options_data) => {
        if (options_data && Object.keys(options_data).length !== 0) {
            var address_data = {};
            var phone = "";
            var email = "";
            var po_number = "";
            if (options_data.shipping_address) {
                address_data = options_data.shipping_address;
                var phone = options_data.user.phone_number;
                var email = options_data.user.registration.email;
                var po_number = options_data.po_number
            } else if (options_data.address_json) {
                address_data = options_data.address_json;
                var phone = options_data.on_call_phone;
                var email = options_data.provider.email;
            } else if (options_data.address) {
                address_data = options_data.address;
                var phone = options_data.phone_number;
                var email = options_data.email;
            }

            await formik.setFieldValue("address.street_address_1", address_data.street_address_1, true);
            await formik.setFieldValue("address.street_address_2", address_data.street_address_2, true);
            await formik.setFieldValue("address.city", address_data.city, true);
            await formik.setFieldValue("address.state", address_data.state, true);
            await formik.setFieldValue("recipient_phone", phone, true);
            await formik.setFieldValue("recipient_email", email, true);
            await formik.setFieldValue("address.postal_code", address_data.postal_code, true);
            await formik.setFieldValue("address.country", address_data.country, true);
            await formik.setFieldValue("special_instructions", po_number, true)
        }
    }

    const createRequest = () => {
        setRequest(formik.values);
    }

    const handleOptionChange = (option, field) => {
        formik.setFieldValue(field, option);
    }

    const handleShippingAddressPopulation = async (event, data, key) => {
        setAltCheck(false);
        if (data) {
            switch (value) {
                case "site":
                    const selectedSite = data.options.find(function(site){
                        return site.value === key
                    });
                    facilityService.getSitesProvidersById(selectedSite.data.id)
                    .then((resp) => {
                        selectedSite.data["provider"] = resp.data.study_coordinator[0];
                        const options_data = selectedSite.data;
                        formik.setFieldValue("patient_id", "");
                        formik.setFieldValue("site_name", options_data.name);
                        formik.setFieldValue("site_id", options_data.id);
                        formik.setFieldValue("recipient_name", options_data.provider.first_name + ' ' + options_data.provider.last_name);
                        if (resp.data.alt_recipient_first_name !== null && resp.data.alt_recipient_last_name !== null){
                            setAltRecipient(resp.data.alt_recipient_first_name+" "+resp.data.alt_recipient_last_name)
                        }else{
                            setAltRecipient(resp.data.study_coordinator[0].first_name+" "+resp.data.study_coordinator[0].last_name);
                        }
                        if(resp.data.alt_recipient_phone !== null){
                            setAltPhone(resp.data.alt_recipient_phone)
                        }else{
                            setAltPhone(resp.data.study_coordinator[0].phone)
                        }
                        if(resp.data.alt_recipient_email !== null){
                            setAltEmail(resp.data.alt_recipient_email)
                        }else{
                            setAltEmail(resp.data.study_coordinator[0].email)
                        }
                        setShippingAddress(options_data);
                        loadFieldValues(options_data);
                        formik.setFieldValue("special_instructions", selectedSite.data.po_number)
                    })
                    .catch((err) => {
                        alertService.error(err);
                    })
                    break;
                case "patient":
                    const selectedPatient = data.options.find(function(patient){
                        return patient.value === key
                    });
                    const patient_options = selectedPatient.data;
                    await formik.setFieldValue("patient_id", selectedPatient.data.user.external_user_id, true);
                    await formik.setFieldValue("site_name", patient_options.site_name, true);
                    await formik.setFieldValue("site_id", 0, true);
                    await formik.setFieldValue("recipient_name", patient_options.user.first_name + " " + patient_options.user.last_name, true);
                    setShippingAddress(patient_options);
                    loadFieldValues(patient_options);
                    break;
                case "fieldRep":
                    const selectedRep = data.options.find(function(rep){
                        return rep.value === key
                    });
                    const field_rep_options = selectedRep.data;
                    formik.setFieldValue("patient_id", "");
                    formik.setFieldValue("site_name", "");
                    formik.setFieldValue("site_id", 0);
                    formik.setFieldValue("recipient_name", field_rep_options.first_name + " " + field_rep_options.last_name);
                    setShippingAddress(field_rep_options);
                    loadFieldValues(field_rep_options);
                    break;
                default:
                    break;
            }
        }
    }

    const switchRecipient = (alternative) => {
        setAltCheck(!altCheck)
        if(alternative){
            const temp_name = altRecipient;
            const temp_phone = altPhone;
            const temp_email = altEmail;
            setAltRecipient(formik.values.recipient_name);
            formik.setFieldValue("recipient_name", temp_name ? temp_name : "");
            setAltPhone(formik.values.recipient_phone);
            formik.setFieldValue("recipient_phone", temp_phone ? temp_phone: "");
            setAltEmail(formik.values.recipient_email);
            formik.setFieldValue("recipient_email", temp_email);
        }
    }

    // Load default form values
    useEffect(() => {
        formik.setFieldValue("requestor_first_name", sessionStorage.getItem("first_name"));
        formik.setFieldValue("requestor_last_name", sessionStorage.getItem("last_name"));
        const day = new Date().toLocaleString("en-US", {day: "2-digit"});
        const month = new Date().toLocaleString("en-US", {month: "2-digit"});
        const year = new Date().getFullYear() % 100;
        const hour = new Date().getHours();
        const min = new Date().getMinutes();
        const sec = new Date().getSeconds();
        const currentDate = month + "-" + day + "-" + year + " " + hour+ ":" + min + ":" + sec;
        formik.setFieldValue("date_requested", currentDate);
    }, []);

    //Set Default date_needed to 4 Days After date_requested
    useEffect(() =>{
        const dateNeeded = new Date();
        dateNeeded.setDate(dateNeeded.getDate() + 3);
        const dateStr = dateNeeded.toLocaleDateString("en-US", {month: "2-digit", day: "2-digit", year: "numeric"}).split("/").join("-");
        if (formik.values.date_needed === ""){
            formik.setFieldValue("date_needed",dateStr);
        }
    },[]);

    // Create quantity options
    useEffect(() => {
        let items = [];
        for(var i = 0; i < 100; i++) {
            items.push({
                text: i,
                key: i,
                value: i
            })
        }
        setQuantities(items);
    }, []);

    // Populate recipient fields
    useEffect(() => {
        if (value === "patient") {
            patientService.getEveryone()
            .then((resp) => {
                let patientArr = [];
                resp.map(item => {
                    patientArr.push(
                        {
                            key: item.id,
                            text: `${item.user.first_name} ${item.user.last_name} (${item.user.external_user_id == null ? " " : item.user.external_user_id})`,
                            value: item.id,
                            data: item,
                        }
                    )
                });
                setOptions(patientArr);
            })
            .catch((err) => {
                alertService.error(err);
            })
        } else if (value === "site") {
            facilityService.getAllSites()
            .then((resp) => {
                let sitesArr = [];
                resp.data.map((item, index) => {
                    sitesArr.push(
                        {
                            key: item.id,
                            text: `${item.name} ( ${item.external_facility_id == null ? " " : item.external_facility_id} )`,
                            value: item.id,
                            data: item,
                        }
                    )
                });
                setOptions(sitesArr);
            })
            .catch((err) => {
                alertService.error(err);
            })
        } else if (value === "fieldRep") {
            userService.getUserByRole("ES_CLINICAL_SITE_MANAGER")
            .then((resp) => {
                let fieldRepArr = [];
                var counter = 0;
                resp.data[0].map(item => {
                    fieldRepArr.push(
                        {
                            key: counter,
                            text: item.first_name + " " + item.last_name,
                            value: counter,
                            data: item,
                        }
                    )
                    counter++;
                })
                setOptions(fieldRepArr);
            })
            .catch((err) => {
                alertService.error(err);
            });
        }
    }, [value]);

    return (
        <Form className="pl-5 pr-4">
            <div className="mt-4">
                <Grid divided='vertically' centered>
                    <GridRow divided>
                        <GridColumn width={5} className='pt-1'>
                        <Header as='h2'>Request Information</Header>
                            <Form.Input
                                fluid
                                disabled={true}
                                label="Requestor First Name"
                                placeholder="First Name"
                                name="requestor_first_name"
                                width={12}
                                onChange={formik.handleChange}
                                error={formik.errors.requestor_first_name}
                                value={formik.values.requestor_first_name}
                            />
                            <Form.Input
                                fluid
                                disabled={true}
                                label="Date Requested"
                                placeholder="01-01-22"
                                name="date_requested"
                                onChange={formik.handleChange}
                                error={formik.errors.date_requested}
                                value={formik.values.date_requested}
                                width={12}
                            />
                            <Form.Input
                                fluid
                                label="Date Needed"
                                placeholder={dateBenchmark.toLocaleDateString("en-US", {month: "2-digit", day:"2-digit", year:"numeric"}).split("/").join("-")}
                                name="date_needed"
                                onChange={formik.handleChange}
                                error={formik.errors.date_needed}
                                value={formik.values.date_needed}
                                width={12}
                            />
                            <Form.Select
                                fluid
                                label="Is this Complaint Request"
                                name="complaint_request"
                                onChange={(event, data) => {
                                    handleOptionChange(data.value, data.name)
                                }}
                                defaultValue={false}
                                error={formik.errors.complaint_request}
                                value={formik.values.complaint_request}
                                width={12}
                                options={[
                                    {
                                        "text": "Yes",
                                        "key": "Yes",
                                        "value": true,
                                    },
                                    {
                                        "text": "No",
                                        "key": "No",
                                        "value": false,
                                    },
                                ]}
                            />
                            {formik.values.complaint_request?<Label className='mb-3' color='yellow' content='Include Incident # in Special Instructions'/>:<></>}
                            <Form.Input
                                fluid
                                label="Order Number"
                                placeholder="SO or TO"
                                name="sales_order_number"
                                readOnly={true}
                                onChange={formik.handleChange}
                                error={formik.errors.sales_order_number}
                                value={formik.values.sales_order_number}
                                width={8}
                            />
                        </GridColumn>
                        
                        <GridColumn width={9} className='pt-1 pl-5'>
                            <Header as='h2'>Recipient Shipping Information</Header>
                            <Form.Group grouped>
                                <Radio
                                    className="pl-3"
                                    label='Facility'
                                    control='input'
                                    name='radioGroup'
                                    value="site"
                                    checked={value === "site"}
                                    onChange={handleChange}
                                />
                                <Radio
                                    className="pl-3"
                                    label='Patient'
                                    control='input'
                                    name='radioGroup'
                                    value="patient"
                                    checked={value === "patient"}
                                    onChange={handleChange}
                                />
                                <Radio
                                    className="pl-3"
                                    label='Field Representative'
                                    control='input'
                                    name='radioGroup'
                                    value="fieldRep"
                                    checked={value === "fieldRep"}
                                    onChange={handleChange}
                                />
                                <Form.Select
                                    fluid
                                    search
                                    className="pb-1"
                                    placeholder="Find Patient or Facility Name"
                                    name="patient_id"
                                    onChange={(event, data) => handleShippingAddressPopulation(event, data, data.value)}
                                    error={formik.errors.patient_id}
                                    value={formik.values.patient_id}
                                    width={8}
                                    options={options}
                                />
                            </Form.Group>
                            
                            <Form.Group inline>
                                <label style={{width:"13.5%", "text-left":"right"}}>Contact Name</label>
                                <Form.Input
                                    width={10}
                                    readOnly={true}
                                    placeholder="Auto populates if site/patient selected"
                                    name="recipient_name"
                                    onChange={formik.handleChange}
                                    error={formik.errors.recipient_name}
                                    value={formik.values.recipient_name}
                                />
                            </Form.Group>
                            <Form.Group inline>
                                <label style={{width:"13.5%", "text-left":"right"}}>Patient Id</label>
                                <Form.Input
                                    width={10}
                                    placeholder="Auto populates if patient selected"
                                    name="patient_id"
                                    onChange={formik.handleChange}
                                    error={formik.errors.patient_id}
                                    value={formik.values.patient_id}
                                />
                            </Form.Group>
                            <Form.Group inline>
                                <label style={{width:"13.5%", "text-left":"right"}}>Hospital Name</label>
                                <Form.Input
                                    inline
                                    width={'8'}
                                    readOnly={true}
                                    placeholder="Auto populates if site/patient selected"
                                    name="site_name"
                                    onChange={formik.handleChange}
                                    error={formik.errors.site_name}
                                    value={formik.values.site_name}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Checkbox
                                    name="different_address"
                                    className="pt-2 pl-3"
                                    label="Use Different Address"
                                    onChange={(event, data) => {
                                        if (data.checked) {
                                            setNewShippingAddress(false);
                                            loadFieldValues(shippingAddress);
                                        } else {
                                            setNewShippingAddress(true);
                                            loadFieldValues(shippingAddress);
                                        }
                                    }}
                                />
                                <Checkbox
                                 className="pt-2 pl-4"
                                    checked={altCheck}
                                    hidden={value!='site'}
                                    name="alternate_recipient"
                                    label="Use Alternate Recipient"
                                    onChange={switchRecipient}
                                />
                            </Form.Group>
                            <Form.Group widths={'equal'}>
                                <Form.Input
                                    fluid
                                    readOnly={newShippingAddress}
                                    label="Street Address"
                                    placeholder={newShippingAddress ? "Auto Populates" : "Street Address 1"}
                                    name="address.street_address_1"
                                    onChange={formik.handleChange}
                                    error={formik.errors.address?.street_address_1}
                                    value={formik.values.address?.street_address_1}
                                />
                                <Form.Input
                                    fluid
                                    readOnly={newShippingAddress}
                                    label="Street Address Line 2"
                                    placeholder={newShippingAddress ? "Auto Populates" : "Street Address 2"}
                                    name="address.street_address_2"
                                    onChange={formik.handleChange}
                                    error={formik.errors.address?.street_address_2}
                                    value={formik.values.address?.street_address_2}
                                />
                            </Form.Group>
                            
                            <Form.Group widths={'equal'}>
                                <Form.Input
                                    fluid
                                    readOnly={newShippingAddress}
                                    label="City"
                                    placeholder={newShippingAddress ? "Auto Populates" : "City"}
                                    name="address.city"
                                    onChange={formik.handleChange}
                                    error={formik.errors.address?.city}
                                    value={formik.values.address?.city}
                                    width={2}
                                />
                                <Form.Select
                                    fluid
                                    disabled={newShippingAddress}
                                    label="State"
                                    placeholder={newShippingAddress ? "Auto Populate" : "State"}
                                    name="address.state"
                                    onChange={(event, data) => handleOptionChange(data.value, data.name)}
                                    error={formik.errors.address?.state}
                                    value={formik.values.address?.state}
                                    width={2}
                                    options={States}
                                />
                                <Form.Input
                                    fluid
                                    readOnly={newShippingAddress}
                                    label="Zip Code"
                                    placeholder={newShippingAddress ? "Auto Populates" : "Zip Code"}
                                    name="address.postal_code"
                                    onChange={formik.handleChange}
                                    error={formik.errors.address?.postal_code}
                                    value={formik.values.address?.postal_code}
                                    width={2}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Select
                                    disabled={newShippingAddress}
                                    label="Country"
                                    placeholder={newShippingAddress ? "Auto Populates" : "Country"}
                                    name="address.country"
                                    onChange={(event, data) => handleOptionChange(data.value, data.name)}
                                    error={formik.errors.address?.country}
                                    value={formik.values.address?.country}
                                    options={Countries}
                                    width={5}
                                />
                                
                            </Form.Group>
                            <Form.Group inline>
                                <label style={{width:"12.5%", "text-left":"right"}}>Recipient Phone</label>
                                <Form.Input
                                    readOnly={newShippingAddress}
                                    placeholder={newShippingAddress ? "Auto Populates" : "xxx-xxx-xxxx"}
                                    name="recipient_phone"
                                    onChange={(event, data) => handleOptionChange(data.value, data.name)}
                                    error={formik.errors.recipient_phone}
                                    value={formik.values.recipient_phone}
                                    options={States}
                                    width={8}
                                />
                            </Form.Group>
                            <Form.Group inline>
                            <label style={{width:"12.5%", "text-left":"right"}}>Recipient Email</label>
                                <Form.Input
                                    width={8}
                                    readOnly={newShippingAddress}
                                    placeholder={newShippingAddress ? "Auto Populates" : "xxxxx@xxx.com"}
                                    name="recipient_email"
                                    onChange={formik.handleChange}
                                    error={formik.errors.recipient_email}
                                    value={formik.values.recipient_email}
                                />
                            </Form.Group>
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn floated={"left"} width={"16"}>
                            <Header as='h1'>Requested Product</Header>
                        </GridColumn>
                        <Form.Group className="pt-2 mt-2 pl-2 ml-3 mr-3">
                        <Grid divided='vertically'>
                            <Grid.Row columns={4}>
                                <GridColumn width={2}>
                                    <Header as='h3'>Quantity</Header>
                                </GridColumn>
                                <GridColumn>
                                    <Header as='h3'>Part/Ref Number</Header>
                                </GridColumn>
                                <GridColumn>
                                    <Header as='h3'>Catalog Name</Header>
                                </GridColumn>
                                <GridColumn>
                                    <Header as='h3'>Catalog Number</Header>
                                </GridColumn>
                            </Grid.Row>
                            {Object.keys(requestedProducts).map(item =>
                                <Grid.Row columns={4}>
                                    <GridColumn width={2}>
                                        <Form.Select
                                            className="pb-2"
                                            fluid
                                            defaultValue={0}
                                            disabled={requestedProducts[item].transferOnly && !transferOrder}
                                            name={`requested_product.${requestedProducts[item].key}`}
                                            onChange={(event, data) => handleOptionChange(data.value, data.name)}
                                            options={quantities}
                                        >
                                        </Form.Select>
                                    </GridColumn>
                                    <GridColumn>
                                        <Label className="pb-2 pt-2">
                                            {requestedProducts[item].partNumber}
                                        </Label>
                                    </GridColumn>
                                    <GridColumn>
                                        <Header as="h5" className="pb-2 pt-2">
                                            {requestedProducts[item].text}
                                        </Header>
                                    </GridColumn>
                                    <GridColumn>
                                        <Label className="pb-2 pt-2">
                                            {requestedProducts[item].catalogNumber}
                                        </Label>
                                    </GridColumn>
                                </Grid.Row>
                            )}
                        </Grid>
                    </Form.Group>
                    </GridRow>
                </Grid>
                
            </div>
            <div className="pt-5 pb-1 pl-2">
                <Form.Group>
                    <Form.Field
                        name="special_instructions"
                        control={TextArea}
                        label="Special Instructions"
                        onChange={formik.handleChange}
                        width={10}
                        value={formik.values.special_instructions}
                    />
                </Form.Group>
            </div>
            <div className="pt-5 mt-5 pl-2">
                <Form.Group>
                    <div className="pr-3">
                    <Modal
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        size="medium"
                        style={inlineStyle.modal}
                        dimmer="inverted"
                        trigger={
                            <Button
                                primary
                                onClick={createRequest}
                                name="submit"
                            >
                                Submit
                            </Button>}
                    >
                        <Modal.Content className="ml-5 pl-5 mr-5 mt-5 mb-5">
                            {request &&
                                <Grid>
                                    <Grid.Row columns={4}>
                                        <GridColumn>
                                            <h3>Review Request Details</h3>
                                        </GridColumn>
                                    </Grid.Row>
                                    <Grid.Row columns={4}>
                                        <GridColumn>
                                            <p>Order: # SO######</p>
                                        </GridColumn>
                                    </Grid.Row>
                                    <Grid.Row columns={4}>
                                        <GridColumn>
                                            <p><b>Requestor First Name</b></p>
                                            <p><b>Requestor Last Name</b></p>
                                            <p><b>Date Requested</b></p>
                                            <p><b>Date Needed</b></p>
                                            <p><b>Protocol Number</b></p>
                                            <p><b>Complaint Request</b></p>
                                            <p><b>Recipient Name</b></p>
                                            <p><b>Shipping Address Line 1</b></p>
                                            <p><b>Shipping Address Line 2</b></p>
                                            <p><b>City</b></p>
                                            <p><b>State</b></p>
                                            <p><b>Zip Code</b></p>
                                            <p><b>Country</b></p>
                                            <p><b>Phone</b></p>
                                            <p><b>Email</b></p>
                                        </GridColumn>
                                        <GridColumn>
                                            <p>{request["requestor_first_name"] ? request["requestor_first_name"] : "N/A"}</p>
                                            <p>{request["requestor_last_name"] ? request["requestor_last_name"] : "N/A"}</p>
                                            <p>{request["date_requested"] ? request["date_requested"] : "N/A"}</p>
                                            <p>{request["date_needed"] ? request["date_needed"] : "N/A"}</p>
                                            <p>{request["sales_order_number"] ? request["sales_order_number"] : "N/A"}</p>
                                            <p>{request["complaint_request"] ? "Yes": "No"}</p>
                                            <p>{request["recipient_name"] ? request["recipient_name"] : "N/A"}</p>
                                            <p>{request["address"] ? request["address"]["street_address_1"] : "N/A"}</p>
                                            <p>{(request["address"] && request["address"]["street_address_2"]) ? request["address"]["street_address_2"] : "N/A"}</p>
                                            <p>{request["address"] ? request["address"]["city"] : "N/A"}</p>
                                            <p>{request["address"] ? request["address"]["state"] : "N/A"}</p>
                                            <p>{request["address"] ? request["address"]["postal_code"] : "N/A"}</p>
                                            <p>{request["address"] ? request["address"]["country"] : "N/A"}</p>
                                            <p>{request["recipient_phone"] ? request["recipient_phone"] : "N/A"}</p>
                                            <p>{request["recipient_email"] ? request["recipient_email"] : "N/A"}</p>
                                        </GridColumn>
                                    </Grid.Row>
                                    <label className="pl-0"><b>Requested Products:</b></label>
                                    {Object.keys(requestedProducts).map(item =>
                                        {if (request["requested_product"] && request["requested_product"][requestedProducts[item].key] !== 0) {
                                            return(
                                            <Grid.Row columns={4}>
                                                <GridColumn>
                                                    <p><b>QTY: {request["requested_product"][requestedProducts[item].key]}</b></p>
                                                </GridColumn>
                                                <GridColumn width={10}>
                                                    <p>{requestedProducts[item].text}</p>
                                                </GridColumn>
                                            </Grid.Row>
                                            )
                                        }}
                                    )}
                                    <Grid.Row columns={4}>
                                        <GridColumn>
                                            <p><b>Special Instructions</b></p>
                                        </GridColumn>
                                        <GridColumn>
                                            <p>{request ? request["special_instructions"] : ""}</p>
                                        </GridColumn>
                                    </Grid.Row>
                                </Grid>
                            }
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                content="Submit"
                                type="submit"
                                onClick={formik.handleSubmit}
                                disabled={formik.isSubmitting}
                                primary
                            >
                                {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Submit
                            </Button>
                            <Button 
                                color='black' 
                                onClick={() => setOpen(false)}
                            >
                            Go Back
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    </div>
                    <Button secondary as={ Link } name='requests' to='/requests'>Cancel</Button>
                </Form.Group>
            </div>
        </Form>
    );
}

export { AddEdit };