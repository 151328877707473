import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Grid, Input, Search } from 'semantic-ui-react';
import { alertService, patientService } from '../_services';


function SearchBar({ handleSearch }) {
  const [searchValue, updateSearchValue] = useState("");
  const [searchPayload, updateSearchPayload] = useState({});
  const [placeholderText, setPlaceholderText] = useState("");
  const page = window.location.pathname.replace(/[\/\\]/g,'');
  const inlineStyle = {
    search: {
      width: "25",
      minWidth:"10em"
    }
  }

  const isNumeric = (num) => {
      return !(isNaN(num) || num === '');
  }

  const patientProviderRequestPayload = {
      name: "",
      external_id: "",
      phone: "",
      record_per_page: sessionStorage.getItem('record_per_page'),
      page_number: 0,
  }

  const ProviderRequestPayload = {
    name: "",
    record_per_page: sessionStorage.getItem('record_per_page'),
    page_number: 0,
}

  const siteRequestPayload = {
    name: "",
    external_id: "",
    record_per_page: sessionStorage.getItem('record_per_page'),
    page_number: 0,
  }

  const requestRequestPayload = {
    request_number: "",
    record_per_page: sessionStorage.getItem('record_per_page'),
    page_number: 0,
  }
  const adminRequestPayload = {
    name: "",
    record_per_page: sessionStorage.getItem('record_per_page'),
    page_number: 0,
  }

  useEffect(() => {
    switch (page) {
      case "patients":
        setPlaceholderText("Patient Name or ID Number");
        updateSearchPayload(patientProviderRequestPayload);
        break;
      case "providers":
        setPlaceholderText("Provider Name");
        updateSearchPayload(ProviderRequestPayload);
        break;
      case "facilities":
        setPlaceholderText("Facility Name or ID Number");
        updateSearchPayload(siteRequestPayload);
        break;
      case "requests":
        setPlaceholderText("Order Number");
        updateSearchPayload(requestRequestPayload);
        break;
      case "administrators":
        setPlaceholderText("Enter a Name");
        updateSearchPayload(adminRequestPayload);
        break;
      default:
        setPlaceholderText("Enter value:");
        updateSearchPayload(patientProviderRequestPayload);
        break;
    }
  }, []);

  const handleSearchChange = () => {
    searchPayload.record_per_page = sessionStorage.getItem('record_per_page');
    switch (page) {
      case "patients":
        if (searchValue.includes('PT-')){
          searchPayload.phone = ""
          searchPayload.external_id = searchValue;
          searchPayload.name = "";
        }
        else if (isNumeric(searchValue) || searchValue.includes("-")) {
          if((searchValue.includes('-') && searchValue.length > 9) || (isNumeric(searchValue) && searchValue.length === 10)){
            searchPayload.phone = searchValue;
            searchPayload.external_id = "";
            searchPayload.name = "";
          }else{
            searchPayload.phone = ""
            searchPayload.external_id = searchValue;
            searchPayload.name = "";
          }
          
        } else {
          searchPayload.phone = "";
          searchPayload.name = searchValue;
          searchPayload.external_id = "";
        }
        handleSearch(searchPayload);
        break;
      case "providers":
        searchPayload.name = searchValue;
        handleSearch(searchPayload);
        break;
      case "facilities":
        if (isNumeric(searchValue)) {
          searchPayload.external_id = searchValue;
          searchPayload.name = "";
        } else {
          searchPayload.name = searchValue;
          searchPayload.external_id = "";
        }
        handleSearch(searchPayload);
        break;
      case "requests":
        searchPayload.request_number = searchValue;
        handleSearch(searchPayload);
        break;
      case "administrators":
        searchPayload.name = searchValue;
        handleSearch(searchPayload);
        break;
      default:
        handleSearch(searchPayload);
        break;
    }
  };

  const onEnterKeyPress = (e) => {
    if(e.key === 'Enter'){
      handleSearchChange();
    }
  }

  return (
    <Grid width='100%'>
      <Grid.Column width={16}>
        <Input
          icon='search' 
          placeholder={placeholderText}
          onChange={(event, data) => updateSearchValue(data.value)}
          className={inlineStyle.search}
          onKeyPress={onEnterKeyPress}
        />
        <Button
          className="ml-3"
          primary 
          name="search_button"
          onClick={handleSearchChange}
        >Search</Button>
      </Grid.Column>
    </Grid>
  )
}

export default SearchBar;