import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const userService = {
    getUserByRole,
    getESUserByRole,
    getUserById,
    create,
    update,
    setPassword,
    confirmEmail,
    updateMfa,
    checkMfa
};
const blank_params = JSON.stringify({"blank" : "blank"});

function getUserByRole(role) {
    return fetchWrapper.get(`${baseUrl}/users/role?name=${role}`);
}

function getESUserByRole(params) {
    return fetchWrapper.post(`${baseUrl}/users/customer_service/list`, params);
}

function getUserById(id) {
    return fetchWrapper.get(`${baseUrl}/users/customer_service/details?id=${id}`, blank_params);
}

function create(params) {
    return fetchWrapper.post(`${baseUrl}/user`, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/users/customer_service?id=${id}`, params);
}

function setPassword(params){
    return fetchWrapper.put(`${baseUrl}/password/update`, params);
}

function confirmEmail(params){
    return fetchWrapper.post(`${baseUrl}/confirm`, params);
}

function updateMfa(params){
    return fetchWrapper.post(`${baseUrl}/mfa/update`, params);
}

function checkMfa(params){
    return fetchWrapper.post(`${baseUrl}/mfa/check`, params);
}