import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const patientService = {
    getAll,
    getEveryone,
    getById,
    create,
    update,
    delete: _delete,
    downloadPatients,
};

function getAll(params) {
    return fetchWrapper.post(`${baseUrl}/patients/patients_list`, params);
}

function getEveryone() {
    return fetchWrapper.get(`${baseUrl}/patients/all`);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/patient/details?id=${id}`);
}

function create(params) {
    delete params.confirm_password
    return fetchWrapper.post(`${baseUrl}/patients`, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/patients?id=${id}`, params);
}

function _delete(params) {
    return fetchWrapper.delete(`${baseUrl}/patients`, params);
}

function downloadPatients() {
    return fetchWrapper.post(`${baseUrl}/patients/download`, {})
}
