import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pagination, Icon, Table } from 'semantic-ui-react';

import { facilityService, alertService } from '../_services';
import SearchBar from '../_components/SearchBar';
import RecordsPerPageSelection from '../_components/RecordsPerPageSelection';
import _ from 'lodash';

function List(props) {
    const { path } = props.match;
    const [selRecordsPerPage, setSelRecordsPerPage] = useState(sessionStorage.getItem("record_per_page"));
    const [facilities, setFacilities] = useState(null);
    const [currentColumnState, setCurrentColumnState] = useState({
        column: null,
        data: null,
        direction: null
    });
    const { column, data, direction } = currentColumnState;
    const [params, setParams] = useState({
        name: "",
        external_id: "",
        record_per_page: sessionStorage.getItem('record_per_page'),
        page_number: 0
    });

    const handleSearch = (searchValue) => {
        search(searchValue);
    }
    
    const handleSort = (event) => {
        if (column !== event.column) {
            setCurrentColumnState({
                column: event.column,
                data: _.sortBy(event.data.data, [event.column]),
                direction: "ascending"
            });
            setFacilities({
                data: _.sortBy(event.data.data, [event.column]),
                record_per_page: facilities.record_per_page,
                page_number: facilities.page_number,
                status_code: facilities.status_code,
                total: facilities.total
            })
            return;
        }
        setCurrentColumnState({
            column: event.column,
            data: facilities.data.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    const handlePagination = (page) => {
        facilityService.getAll({
            name: "",
            external_id: "",
            record_per_page: sessionStorage.getItem('record_per_page'),
            page_number: Math.ceil(page)
        })
        .then(x => setFacilities(x));
    }

    const search = (searchParam) => {
        facilityService.getAll(searchParam)
        .then(x => setFacilities(x));
    };

    const handleRPP = (e, data) => {
        setSelRecordsPerPage(data.value);
        setParams( {
            name: params.name,  
            record_per_page: selRecordsPerPage, 
            page_number: params.page_number
        });
    };

    useEffect(() => {
        handlePagination(params.page_number);
    }, [selRecordsPerPage]);

    useEffect(() => { facilityService
        facilityService.getAll(params)
        .then(x => setFacilities(x));
    }, []);

    function deleteSite(id) {
        setFacilities(facilities.data.map(delete_response => {
            if (delete_response.id === id) { delete_response.isDeleting = true; }
            return x;
        }));
        facilityService.delete(id).then(() => {
            setFacilities(facilities => facilities.filter(x => x.id !== id));
        });
    }

    return (
        <div>
            <div className="mt-4 pt-2">
                <div className="ui two column grid" width="100%">
                   <div className="fourteen wide column column-addon">
                        <SearchBar handleSearch={handleSearch}/>
                    </div>
                    <div className="two wide column column-addon">
                        <RecordsPerPageSelection handleSelection={ handleRPP } />
                    </div>
                </div>
                <Table striped sortable>
                    <Table.Header multiline>
                        <Table.HeaderCell
                            sorted={column == 'name' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'name', data: facilities})}
                        >
                            Facility Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'external_id' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'external_id', data: facilities})}
                        >
                            Facility ID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'address' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'city', data: facilities})}
                        >
                            Facility Location
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'num_of_patients' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'num_of_patients', data: facilities})}
                        >
                            Enrolled <br/> Patients
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'site_manager_name' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'site_manager_name', data: facilities})}
                        >
                            Primary Territory <br/>  Manager
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'primary_study_coordinator_name' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'primary_study_coordinator_name', data: facilities})}
                        >
                            Primary Facility <br/> Coordinator Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'phone' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'phone', data: facilities})}
                        >
                            Primary Facility <br/> Coordinator Phone
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'study_coordinator_count' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'study_coordinator_count', data: facilities})}
                        >
                            Other Facility <br/> Coordinators
                        </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {facilities && facilities.data.map(facility =>
                            <Table.Row key={facility.facility_id}>
                                <Table.Cell selectable ><Link to={`${path}/view/${facility.facility_id}`}>{facility.name}</Link></Table.Cell>
                                <Table.Cell>{facility.external_id}</Table.Cell>
                                <Table.Cell>{`${facility.city}, ${facility.state}`}</Table.Cell>
                                <Table.Cell>{facility.num_of_patients}</Table.Cell>
                                <Table.Cell>{facility.site_manager_name}</Table.Cell>
                                <Table.Cell>{facility.primary_study_coordinator_name}</Table.Cell>
                                <Table.Cell>{!facility.phone.includes("-") && (facility.phone.length < 11) ? facility.phone.slice(0,3)+"-"+facility.phone.slice(3,6)+"-"+facility.phone.slice(6) : facility.phone}</Table.Cell>
                                <Table.Cell>{facility.study_coordinator_count > 1 ? 'Yes' : 'No'}</Table.Cell>
                            </Table.Row>
                        )}
                        {!facilities &&
                            <Table.Row>
                                <Table.Cell colSpan="6" className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {facilities && !facilities.data.length &&
                            <Table.Row>
                                <Table.Cell colSpan="8" className="text-center">
                                    <div className="p-2">No Sites To Display</div>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>

                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <Pagination className="mt-5 mb-5"
                        onPageChange={(event, data) => handlePagination(data.activePage-1)}
                        defaultActivePage={facilities ? facilities.page_number : 1}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={facilities ? Math.ceil(facilities.total / facilities.record_per_page) : 0}
                    />
                </div>
            </div>
        </div>
    );
}

export { List };
