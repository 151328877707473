import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pagination, Icon, Table } from 'semantic-ui-react';

import { materialService, alertService } from '../_services';
import SearchBar from '../_components/SearchBar';
import RecordsPerPageSelection from '../_components/RecordsPerPageSelection';

function List(props) {
    const { path } = props.match;
    const [selRecordsPerPage, setSelRecordsPerPage] = useState(sessionStorage.getItem("record_per_page"));
    const [requests, setRequests] = useState(null);
    const [state, setState] = useState({
        column: null,
        data: null,
        direction: null
    });
    const { column, data, direction } = state
    const [params, setParams] = useState({
        request_number: "",
        record_per_page: sessionStorage.getItem('record_per_page'),
        page_number: 0
    });

    const handleSearch = (searchValue) => {
        search(searchValue);
    };

    const handleSort = (event) => {
        if (column !== event.column) {
            setState({
                column: event.column,
                data: _.sortBy(event.data, [event.column]),
                direction: "ascending"
            });
            setRequests({
                data: _.sortBy(event.data.data, [event.column]),
                record_per_page: requests.record_per_page,
                page_number: requests.page_number,
                status_code: requests.status_code,
                total: requests.total
            });
            return;
        }
        setState({
            column: event.column,
            data: requests.data.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    const handlePagination = (page) => {
        materialService.getAll({
            request_number: "",
            record_per_page: sessionStorage.getItem('record_per_page'),
            page_number: Math.ceil(page)
        })
        .then(x => {
            setRequests(x);
        });
    }

    const search = (searchParam) => {
        materialService.getAll(searchParam)
        .then(x => setRequests(x));
    };

    const handleRPP = (e, data) => {
        setSelRecordsPerPage(data.value);
        setParams( {
            name: params.name,  
            record_per_page: selRecordsPerPage, 
            page_number: params.page_number
        });
    };

    useEffect(() => {
        handlePagination(params.page_number);
    }, [selRecordsPerPage]);
        
    useEffect(() => { materialService
        materialService.getAll(params)
        .then(x => setRequests(x));
    }, []);

    return (
        <div>
            <div className="mt-4 pt-2">
                <div className="ui two column grid" width="100%">
                    <div className="fourteen wide column column-addon">
                        <SearchBar handleSearch={handleSearch}/>
                    </div>
                    <div className="two wide column column-addon">
                        <RecordsPerPageSelection handleSelection={ handleRPP } />
                    </div>
                </div>
                <Table striped sortable>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell
                            sorted={column == 'request_number' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'request_number', data: requests})}
                        >
                            Order Number
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'requestor' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'requestor', data: requests})}
                        >
                            Requestor
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'date_requested' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'date_requested', data: requests})}
                        >
                            Date Requested
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'recipient' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'recipient', data: requests})}
                        >
                            Recipient
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'item_requested' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'item_requested', data: requests})}
                        >
                            Item Requested
                        </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {requests && requests.data.map(req =>
                            <Table.Row key={req.request_id}>
                                <Table.Cell selectable style={{display:'flex', alignItems:'center', valign:'middle', verticalAlign:"middle"}}>
                                    
                                    <Link to={`${path}/report/${req.request_number}`} style={{width: '100%'}}>
                                        
                                            {req.request_number}
                                        
                                    </Link>
                                </Table.Cell>
                                <Table.Cell>{req.requestor}</Table.Cell>
                                <Table.Cell>{req.date_requested}</Table.Cell>
                                <Table.Cell>{req.recipient}</Table.Cell>
                                <Table.Cell>{req.items_requested}</Table.Cell>
                            </Table.Row>
                        )}
                        {!requests &&
                            <Table.Row>
                                <Table.Cell colSpan="6" className="text-center">
                                    <div className="p-2">No Requests To Display</div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {requests && !requests.data.length &&
                            <Table.Row>
                                <Table.Cell colSpan="6" className="text-center">
                                    <div className="p-2">No Requests To Display</div>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>

                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <Pagination className="mt-5 mb-5"
                        onPageChange={(event, data) => handlePagination(data.activePage-1)}
                        defaultActivePage={requests ? requests.page_number : 1}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={requests ? Math.ceil(requests.total / requests.record_per_page) : 0}
                    />
                </div>
            </div>
        </div>
    );
}

export { List };
