import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Login from '../session/Login';
import { sessionService, alertService } from '../_services';
import useToken from '../session/Token';

function Logout({ match }) {
    const history = useHistory();
    const { path } = match;
    const { token, setToken } = useToken();

    useEffect(() => {
        sessionService
          .delete()
          .then(() => {
            history.push("/login");
        }).catch(() => {
            // With error still push to login
            history.push("/login");
            alertService.error;
        })
    }, []);

    function deleteSession(){
        return false
    }

    return  <Login setToken={deleteSession} />
}

export { Logout };
