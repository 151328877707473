import axios from 'axios';
import { uspsBaseUrl } from '../models/Constants';
const xmlbuilder2 = require('xmlbuilder2');
let USER_ID = process.env.REACT_APP_USPS_ID;

const buildURL = (data) =>{
    let root = xmlbuilder2.create({version: '1.0'})
      .ele('AddressValidateRequest', {'USERID': USER_ID})
        .ele('Address')
          .ele('Address1').txt(data?.street_address_1).up()
          .ele('Address2').txt(data?.street_address_2).up()
          .ele('City').txt(data?.city).up()
          .ele('State').txt(data?.state).up()
          .ele('Zip5').txt(data?.postal_code).up()
          .ele('Zip4').txt('').up()
        .up()
      .up();
    let xml = root.end({prettyPrint: true});
    return uspsBaseUrl + encodeURIComponent(xml);
}

export const verifyAddress = async (data) =>{
    let url = buildURL(data);
    return await axios.get(url)
    .then(res => {
        const json_obj = xmlbuilder2.convert(res.data,{format: 'object'});
        const address = json_obj['AddressValidateResponse']['Address']
        if(!address['Address2'] || !address['City'] || !address['State'] || !address['Zip5']){
            console.log("Bad Response in USPS Verification")
            return null
        }else{
            const formatted_address = {};
            if (address['Address1']==null){
                formatted_address['street_address_1'] = address['Address2'];
                formatted_address['street_address_2'] = '';
            }else{
                formatted_address['street_address_1'] = address['Address1'];
                formatted_address['street_address_2'] = address['Address2'];
            }
            formatted_address['city'] = address['City'];
            formatted_address['state'] = address['State'];
            formatted_address['postal_code'] = address['Zip5'];
            formatted_address['country'] = 'USA';
            return formatted_address
        }
        
    }).catch(err => {
        console.log(url)
        console.log(USER_ID)
        console.log('Error in verifyAddress function:');
        console.log(err)
        return null
    })
}