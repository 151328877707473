import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const sessionService = {
    create,
    login,
    delete: _delete
};

function create(params) {
    return fetchWrapper.post(`${baseUrl}/auth/token`, params);
}

function login(params){
    return fetchWrapper.post(`${baseUrl}/client/auth`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete() {
    let refresh_token = sessionStorage.getItem('refresh_token');
    
    let resp = await fetchWrapper.post(`${baseUrl}/logout`, {'refresh_token': refresh_token});
    sessionStorage.clear()
    return resp;
}
