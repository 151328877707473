import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const facilityService = {
    getAll,
    getAllSites,
    getById,
    getSitesProviders,
    getSitesProvidersById,
    create,
    update,
    delete: _delete
};

function getAll(params) {
    return fetchWrapper.post(`${baseUrl}/facilities/list`, params);
}

function getAllSites(params) {
    return fetchWrapper.get(`${baseUrl}/facilities`);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/facility/detail?id=${id}`)
}

function getSitesProviders() {
    return fetchWrapper.get(`${baseUrl}/facilities/providers`);
}

function getSitesProvidersById(id) {
    return fetchWrapper.get(`${baseUrl}/facilities/facility_providers?id=${id}`);
}

function create(params) {
    delete params.confirm_password;
    return fetchWrapper.post(`${baseUrl}/facilities`, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/facilities?id=${id}`, params);
}

/****** TO BE IMPLEMENTED LATER ******/
// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}
