import {
    Form,
    Button,
    Header,
} from 'semantic-ui-react';
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './styles.css';
import { alertService, userService } from '../_services';

export default function SetPassword(){
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState("");


    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required'),
        retypedPass: Yup.string()
            .required('Passwords must match')
    });
    useEffect(() => {
        setEmail(sessionStorage.getItem("userEmail"));
    }, []);

    function onSubmit(data){
        if(formik.values.password != formik.values.retypedPass){
            alertService.error('Passwords must match.');
            return formik.setSubmitting(false);
        }else{
            const payload = {
                'email': email,
                'newpassword': formik.values.password
            }
            userService.setPassword(payload).then((data) => {
                sessionStorage.setItem('userPassword', formik.values.password);
                return history.push('/SelectMFA');
            }).catch((error)=>{
                alertService.error(error);
                return formik.setSubmitting(false);
            });
        }
    }

    const formik = useFormik({
        initialValues: {
            password: "",
            retypedPass: "",
        },
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            onSubmit(formData);
        }
    });

    return (
        <div>
        <div className="container mt-5">
            <div class="formWrapper">
                <div className='borderBox'>
                    <div className='borderPadding'>
                        <Header as='h1' textAlign='center'>Choose Your Password</Header>
                        <div className='promptHeader'>
                                Password must be at least 8 characters and have one uppercase, one lowercase, one number, and one symbol.
                        </div>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Input
                                name='password'
                                autoComplete='off'
                                type='password'
                                placeholder='Password'
                                label='Select Password'
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                error={formik.errors.password}
                            />
                            <div style={{'padding-top': '15px'}}/>
                            <Form.Input 
                                name='retypedPass'
                                autoComplete='off'
                                type='password'
                                placeholder='Password'
                                label='Please Retype Password'
                                onChange={formik.handleChange}
                                value={formik.values.retypedPass}
                                error={formik.errors.retypedPass}
                            />
                            <Button 
                                type="submit"
                                disabled={formik.isSubmitting} 
                                className="btn btn-primary"
                                onSubmit={formik.handleSubmit}
                            >
                                {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}