import { useEffect, useState } from "react";
import { alertService, materialService } from "../_services";
import { Document, Page, pdfjs } from 'react-pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { Button } from 'semantic-ui-react';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

export default function Report({history, match}){
    const { id } = match.params;
    const [reportLink, setReportLink] = useState('');
    const [excelLink, setExcelLink] = useState('');
    const [numPages, setNumPages] = useState(0);

    //Will request the signed link from the backend once on load of page
    useEffect(()=> {
        const payload = {
            "pdr_id": id
        };
        materialService.getLink(payload)
        .then((data)=>{
                setReportLink(data['report']);
                setExcelLink(data['excel']);
        })
        .catch((error)=> {
            alertService.error(`Could not load PDR: ${error}`);
        })
    }, [id]);

    function onDocumentLoadSuccess(pdf) {
        setNumPages(pdf.numPages);
    }

    const handleReportDownload = () => {
        const link = document.createElement('a');
        link.href = reportLink;
        link.download = id;
        link.click();
    }

    const handleExcelDownload = () => {
        console.log('excel')
        const link = document.createElement('a');
        link.href = excelLink;
        link.download = id;
        link.click();
    }

    return (
    <div style={{'text-align': 'center', display: 'inline-block', width: '100%'}}>
        <div style={{'text-align': 'left', 'display': 'inline-block', width: '100%', marginLeft: '12.5rem'}}>
            <Button onClick={handleReportDownload} color="blue">Download PDF</Button>
            <Button onClick={handleExcelDownload} color="blue">Download Excel</Button>
        </div>
        <Document
            file={reportLink}
            onLoadSuccess={(pdf) => {
                onDocumentLoadSuccess(pdf);
            }}
            onLoadError={(error)=> {
                alertService.error(error);
            }}
            >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false}/>
            ))}
        </Document>
    </div>
    );
}