import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pagination, Icon, Table, Popup, Label, TableCell } from 'semantic-ui-react';
import {
    esRolesDict
} from '../models/Constants'
import { userService, alertService } from '../_services';
import SearchBar from '../_components/SearchBar';
import RecordsPerPageSelection from '../_components/RecordsPerPageSelection';

function List(props) {
    const { path } = props.match;
    const [selRecordsPerPage, setSelRecordsPerPage] = useState(sessionStorage.getItem("record_per_page"));
    const [esUsers, setEsUsers] = useState(null);
    const [state, setState] = useState({
        column: null,
        data: null,
        direction: null
    });
    const { column, data, direction } = state
    const [params, setParams] = useState({
        name: "",
        record_per_page: sessionStorage.getItem('record_per_page'),
        page_number: '0'
    });

    const handleSearch = (searchValue) => {
        search(searchValue);
    }

    const handleSort = (event) => {
        if (column !== event.column) {
            setState({
                column: event.column,
                data: _.sortBy(event.data, [event.column]),
                direction: "ascending"
            });
            setEsUsers({
                data: _.sortBy(event.data.data, [event.column]),
                record_per_page: esUsers.record_per_page,
                page_number: esUsers.page_number,
                status_code: esUsers.status_code,
                total: esUsers.total
            });
            return;
        }
        setState({
            column: event.column,
            data: esUsers.data.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    const handlePagination = async (page) => {
        params.record_per_page = sessionStorage.getItem('record_per_page');
        params.page_number = page;
        await userService.getESUserByRole(params)
        .then(x => 
            {         
                setEsUsers(x);
            });
    }

    const search = (searchParam) => {
        userService.getESUserByRole(searchParam)
        .then(x =>
            {
                setEsUsers(x);
            });
    };

    const handleRPP = (e, data) => {
        setSelRecordsPerPage(data.value);
        setParams( {
            name: params.name,  
            record_per_page: selRecordsPerPage, 
            page_number: params.page_number,
            status_id: params.status_id
          });
    };

    useEffect(() => {
        handlePagination(params.page_number);
    }, [selRecordsPerPage]);
        
    useEffect(() => { userService
        userService.getESUserByRole(params)
        .then(_studyManagers =>
            {
                setEsUsers(_studyManagers);
            });
    }, []);

    
    function handleResponseFailure(error) {
        alertService.error(error)
    }

    return (
        <div>
            <div className="mt-4 pt-2">
                <div className="ui two column grid" width="100%">
                    <div className="fourteen wide column column-addon">
                        <SearchBar handleSearch={handleSearch}/>
                    </div>
                    <div className="two wide column column-addon">
                        <RecordsPerPageSelection handleSelection={ handleRPP } />
                    </div>
                </div>
                <Table striped sortable>
                    <Table.Header>
                        <Table.HeaderCell
                            sorted={column == 'full_name' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'first_name', data: esUsers})}
                        >
                            ES User Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={column == 'role' ? direction: null}
                        onClick={()=> handleSort({type: 'CHANGE_SORT', column: 'role', data: esUsers})}
                        >
                            Role
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'phone' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'phone_number', data: esUsers})}
                        >
                            Phone Number
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'email_address' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'email', data: esUsers})}
                        >
                            Email Address
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'address' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'address', data: esUsers})}
                        >
                             Address
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={column == 'locked status' ? direction : null}
                        onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'locked', data: esUsers})}
                        >
                        Locked Status
                        </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {esUsers && esUsers.data.map(esUser =>
                            <Table.Row key={esUser.user_id}>
                                <Table.Cell selectable style={{display:'flex', alignItems:'center', valign:'middle', verticalAlign:"middle"}}>
                                    <Popup content={esUser.deactivate === true ? "Disabled" : "Enrolled"} trigger={esUser.deactivate === true ? <Icon style={{paddingLeft: '10px', paddingBottom: '19px'}}  name='minus circle' color='red'/>:<Icon style={{paddingLeft: '10px', paddingBottom: '19px'}} name='check circle' color='green'/>}></Popup>
                                    <Link to={`${path}/view/${esUser.user_id}`}>{esUser.first_name+" "+esUser.last_name}</Link></Table.Cell>
                                <Table.Cell>{esRolesDict[esUser.role]}</Table.Cell>
                                <Table.Cell>{!esUser.phone_number.includes("-") && (esUser.phone_number.length < 11) ? esUser.phone_number.slice(0,3)+"-"+esUser.phone_number.slice(3,6)+"-"+esUser.phone_number.slice(6) : esUser.phone_number}</Table.Cell>
                                <Table.Cell>{esUser.email}</Table.Cell>
                                <Table.Cell>{esUser.address.city + ", " + esUser.address.state}</Table.Cell>
                                <Table.Cell textAlign='center'>{
                                    <Popup content={esUser.locked? "Locked" : "Unlocked"} trigger={esUser.locked? <Icon style={{paddingBottom: '19px'}}  name='lock closed' color='red'/>:<Icon style={{paddingBottom: '19px'}} name='lock open' color='green'/>}></Popup>    
                                }</Table.Cell>
                            </Table.Row>
                        )}
                        {!esUsers &&
                            <Table.Row>
                                <Table.Cell colSpan="4" className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {esUsers && !esUsers.data.length &&
                            <Table.Row>
                                <Table.Cell colSpan="4" className="text-center">
                                    <div className="p-2">No ES Users To Display</div>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>

                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <Pagination className="mt-5 mb-5"
                        onPageChange={(event, data) => handlePagination(data.activePage-1)}
                        defaultActivePage={esUsers ? esUsers.page_number : 1}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={esUsers ? Math.ceil(esUsers.total / esUsers.record_per_page) : 0}
                    />
                </div>
            </div>
        </div>
    );
}

export { List };
