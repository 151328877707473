import React, { useState, useEffect, useReducer, useRef} from 'react';
import { Dropdown } from 'semantic-ui-react';

const RecordsPerPageSelection = ({ handleSelection }) => {
    const rppOpts = [{ key: 'rppOpts1', value: '10', text: '10' },
                 { key: 'rppOpts2', value: '15', text: '15' },
                 { key: 'rppOpts3', value: '20', text: '20' },
                 { key: 'rppOpts4', value: '25', text: '25' },
                 { key: 'rppOpts5', value: '30', text: '30' }, ];
    return (
        <Dropdown placeholder='Records / Page' fluid selection options={rppOpts} 
            onChange={(e, data) => {
                sessionStorage.setItem('record_per_page', data.value);
                handleSelection(e, data); 
            }} />
    )
};

export default RecordsPerPageSelection;