import React, { useEffect, useState } from 'react';
import {
  Button,
  Header,
  Modal
} from 'semantic-ui-react';

const IdleTimerModal = ({ showModal, handleClose, handleLogout, timeoutInMins, remainingTime }) => {

  return (
    <Modal
      onClose={handleClose}
      open={showModal}
    >
      <Modal.Content>
        <Modal.Header></Modal.Header>
        <Modal.Description>
          <Header>No activity has been detected in last {timeoutInMins} minutes</Header>
          <p>The app will auto-logout if no activity is found</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={handleLogout}>
          Logout
        </Button>
        <Button primary onClick={handleClose}>
          Stay
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default IdleTimerModal;