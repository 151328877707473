import React from 'react';
import { NavLink } from 'react-router-dom';

import ElementLogo from '../assets/main_logo_sm.png';
import { version } from '../models/Constants';

function Nav() {
    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark nav">
            <div class="d-flex flex1">
                <img src={ElementLogo} className="scale down"/>
            </div>
            <div class="d-flex flex1 justify-content-center" id="div2">
                <h3 style={{color: '#ffffff'}}>Patient Management Portal</h3>
            </div>
            <div class="d-flex flex1 justify-content-end" id="div3">
                <h6 class="version" style={{color: '#ffffff'}}>v02.03.000</h6>
                <ul className="navbar-nav ml-4">
                    <li className="nav-item">
                        <NavLink to="/logout" className="nav-item nav-link">Logout</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

function Banner() {
    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark nav">
            <div class="d-flex flex1">
                <img src={ElementLogo} className="scale down"/>
            </div>
            <div class="d-flex flex1 justify-content-center" id="div2">
                <h3 style={{color: '#ffffff'}}>Patient Management Portal</h3>
            </div>
            <div class="d-flex flex1 justify-content-end" id="div3">
                <p class="version" style={{color: '#ffffff'}}>v{version}</p>
            </div>
            <ul className="navbar-nav ml-auto"/>
        </nav>
    );
}

export { Banner };

export { Nav };
