import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const providerService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    downloadProviders
};

function getAll(params) {
    return fetchWrapper.post(`${baseUrl}/providers/list`, params);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/providers?id=${id}`)
}

function create(params) {
    delete params.confirm_password;
    return fetchWrapper.post(`${baseUrl}/providers`, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/providers?id=${id}`, params);
}

/****** TO BE IMPLEMENTED LATER ******/
// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/providers/delete?id=${id}`,id);
}

function downloadProviders() {
    return fetchWrapper.post(`${baseUrl}/providers/download`, {})
}